export function usersPageHeaders(i18n) {
  return [
    { text: i18n.t('usersPageHeaders.firstName'), value: 'first_name' },
    { text: i18n.t('usersPageHeaders.lastName'), value: 'last_name' },
    { text: i18n.t('usersPageHeaders.email'), value: 'email' },
    { text: i18n.t('usersPageHeaders.role'), value: 'role' },
    { text: i18n.t('usersPageHeaders.client'), value: 'client.company_name' },
    { text: i18n.t('usersPageHeaders.created_at'), value: 'created_at' },
    {
      text: i18n.t('usersPageHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function ordersHeaders(i18n) {
  return [
    { text: i18n.t('ordersHeaders.orderNumber'), value: 'id' },
    {
      text: i18n.t('ordersHeaders.orderStatus'),
      value: 'status',
    },
    {
      text: i18n.t('ordersHeaders.pickupLocation'),
      value: 'pickup_location_name',
    },
    { text: i18n.t('ordersHeaders.collectorsName'), value: 'collector_id' },
    { text: i18n.t('ordersHeaders.clientsName'), value: 'client_id' },
    { text: i18n.t('ordersHeaders.startTime'), value: 'pickup_time' },
    {
      text: i18n.t('ordersHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function ordersBasicHeaders(i18n) {
  return [
    { text: i18n.t('ordersHeaders.orderNumber'), value: 'number' },
    {
      text: i18n.t('ordersHeaders.locationFrom'),
      value: 'from_location_name',
    },
    { text: i18n.t('ordersHeaders.locationTo'), value: 'to_location_name' },
    { text: i18n.t('ordersHeaders.collectorsName'), value: 'user_id' },
    { text: i18n.t('ordersHeaders.startTime'), value: 'start' },
    {
      text: i18n.t('ordersHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function notificationsHeaders(i18n) {
  return [
    { text: i18n.t('notificationsHeaders.time'), value: 'time' },
    {
      text: i18n.t('notificationsHeaders.notification'),
      value: 'notification',
    },
  ];
}

export function clientsHeaders(i18n) {
  return [
    { text: i18n.t('clientsHeaders.name'), value: 'company_name' },
    // { text: i18n.t('clientsHeaders.address'), value: 'address' },
    { text: i18n.t('clientsHeaders.phoneNumber'), value: 'phone' },
    // { text: i18n.t('clientsHeaders.email'), value: 'email' },
    // { text: i18n.t('clientsHeaders.vat'), value: 'vat_number' },
    { text: i18n.t('clientsHeaders.pib'), value: 'pib' },
    { text: i18n.t('clientsHeaders.clientType'), value: 'client_type' },
    { text: i18n.t('clientsHeaders.contactPersonName'), value: 'contact_person_name' },
    { text: i18n.t('clientsHeaders.organisation'), value: 'organisation.name' },
    { text: i18n.t('clientsHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('clientsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function organisationsHeaders(i18n) {
  return [
    { text: i18n.t('organisationsHeaders.name'), value: 'name' },
    { text: i18n.t('organisationsHeaders.created'), value: 'created_at' },
    {
      text: i18n.t('organisationsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function joinRequestsHeaders(i18n) {
  return [
    { text: i18n.t('clientsHeaders.name'), value: 'company_name' },
    { text: i18n.t('clientsHeaders.address'), value: 'address' },
    { text: i18n.t('clientsHeaders.phoneNumber'), value: 'phone' },
    { text: i18n.t('clientsHeaders.email'), value: 'email' },
    { text: i18n.t('clientsHeaders.vat'), value: 'vat_number' },
    { text: i18n.t('clientsHeaders.pib'), value: 'pib' },
    { text: i18n.t('clientsHeaders.contactPersonName'), value: 'contact_person_name' },
    { text: i18n.t('clientsHeaders.created'), value: 'created_at' },
    {
      text: i18n.t('clientsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function sponsorsHeaders(i18n) {
  return [
    { text: i18n.t('sponsorsHeaders.title'), value: 'title' },
    { text: i18n.t('sponsorsHeaders.description'), value: 'description' },
    { text: i18n.t('sponsorsHeaders.link'), value: 'link' },
    { text: i18n.t('sponsorsHeaders.type'), value: 'type' },
    { text: i18n.t('sponsorsHeaders.createdAt'), value: 'created_at' },
    { text: i18n.t('sponsorsHeaders.moveUp'), value: 'move_up', sortable: false, align: 'center' },
    { text: i18n.t('sponsorsHeaders.moveDown'), value: 'move_down', sortable: false, align: 'center' },
    {
      text: i18n.t('sponsorsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function mainBannerHeaders(i18n) {
  return [
    { text: i18n.t('mainBannerHeaders.title'), value: 'title' },
    { text: i18n.t('mainBannerHeaders.cta_link'), value: 'cta_link' },
    { text: i18n.t('mainBannerHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('mainBannerHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function articlesHeaders(i18n) {
  return [
    { text: i18n.t('articlesHeaders.title'), value: 'title' },
    { text: i18n.t('articlesHeaders.intro_text'), value: 'intro_text' },
    { text: i18n.t('articlesHeaders.publishDate'), value: 'publish_date' },
    {
      text: i18n.t('articlesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function eventsHeaders(i18n) {
  return [
    { text: i18n.t('eventsHeaders.title'), value: 'title' },
    { text: i18n.t('eventsHeaders.event_date'), value: 'event_date' },
    { text: i18n.t('eventsHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('eventsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function contactMessagesHeaders(i18n) {
  return [
    { text: i18n.t('contactMessagesHeaders.title'), value: 'title' },
    { text: i18n.t('contactMessagesHeaders.body'), value: 'body' },
    { text: i18n.t('contactMessagesHeaders.subject'), value: 'subject' },
    { text: i18n.t('contactMessagesHeaders.email'), value: 'email' },
    { text: i18n.t('contactMessagesHeaders.phone'), value: 'phone' },
    { text: i18n.t('contactMessagesHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('contactMessagesHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function albumsHeaders(i18n) {
  return [
    { text: i18n.t('albumsHeaders.title'), value: 'title' },
    { text: i18n.t('albumsHeaders.description'), value: 'description' },
    { text: i18n.t('albumsHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('albumsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function albumItemsHeaders(i18n) {
  return [
    { text: i18n.t('albumItemsHeaders.file'), value: 'title' },
    { text: i18n.t('albumItemsHeaders.createdAt'), value: 'created_at' },
    {
      text: i18n.t('albumItemsHeaders.actions'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ];
}

export function costEffectivenessReportHeaders(i18n, filterOption) {
  let colNumber;

  colNumber = filterOption === 1 ? 3 : 4;
  const headers = [{ text: i18n.t('reportsHeaders.vehicle'), value: 'vehicleName' }];

  for (let i = 0; i < colNumber; i++) {
    headers.push(
      { text: i18n.t('reportsHeaders.earnings'), value: `data[${i}].earnings` },
      { text: i18n.t('reportsHeaders.expenses'), value: `data[${i}].cost` },
      { text: i18n.t('reportsHeaders.profit'), value: `data[${i}].profit` }
    );
  }

  return headers;
}

export function utilizationReportHeaders(i18n, filterOption) {
  let colNumber;
  colNumber = filterOption === 1 ? 3 : 4;
  const headers = [{ text: i18n.t('reportsHeaders.vehicle'), value: 'vehicleName' }];

  for (let i = 0; i < colNumber; i++) {
    headers.push(
      { text: i18n.t('reportsHeaders.daysWorking'), value: `data[${i}].days_working` },
      { text: i18n.t('reportsHeaders.usageIndex'), value: `data[${i}].usage_index` }
    );
  }

  return headers;
}
